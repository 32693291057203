@import './sass/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  background-color: #000000;
  color: #ffffff;
  width: 100%;
}

.content {
  width: 100%;
  max-width: $max-container-width;
  align-self: center;
}