@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';

.container {
  margin-bottom: 100px;

  p {
    padding: 1.5rem 0px;
    line-height: 1.5;
    color: $color-grey-1;
  }
}

.content {
  max-width: $max-container-width;
  margin: 0 auto;
}

h1 {
  font-size: $font-size-1;
  line-height: $font-size-1;

    @include respond($mid) {
      font-size: $font-size-2;
      line-height: $font-size-2;
      font-weight: 700;
    }
  }

h2 {
  margin-bottom: 1rem;
}


.desc_2 {
  color: $color-main;
  
}

.whiteText {
  color: #ffffff;
  font-weight: 500;
}

.action {
  margin: 1.5rem;
  animation: pulse 1s ease infinite;

  @include respond($mid) {
    margin-top: 4rem;
  }
}

.link {
  font-size: 30px;
  font-weight: 700;
  color: $color-white;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    color: $color-main;
  }

  & span {
    color: $color-main;
    font-weight: 700;
  }
}

.tools {
  margin-top: 4rem;
}

.tech {
  background-color: $color-grey-4;
  color: $color-grey-1;
  font-size: 1.6rem;
  font-weight: 500;
  display: inline-block;
  padding: 2px 10px;
  margin: 0 10px 10px 0px;
  border-radius: 3px;
  transition: all .3s ease;

  &:hover {
    background-color: $color-main;
    color: #ffffff;
  }
}

.experience {
  margin-top: 9rem;
  scroll-margin-top: 40px;

  &_item {
    border-radius: 16px;
    margin-top: 3rem;
  }
}

.year {
  font-size: 1.5rem;
  margin-top: -10px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}