@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 16px;
  color: $color-grey-3;
  text-align: center;
  margin: -10px -5px 0 -5px;
  animation: move 1.5s ease;

  @include respond($mid) {
    align-self: flex-start;
    position: sticky;
    padding: 25px;
    margin: 0;
    top: 50px;
  }

  & h1 {
    color: #000000;
    font-size: 40px;
    font-weight: 700;

    @include respond($mid) {
    line-height: 1.5;
    }
  }
}

.imageContainer {
  width: 25rem;
  height: auto;
}

.image {
  width: 100%;
  border-radius: 16px;
}

.content {
  width: 100%;
  max-width: 400px;
}

.text {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: $color-grey-5;

  @include respond($mid) {
    max-width: 400px;
  }
}

.links {
  display: flex;
  width: 100%;
  // justify-content: stretch;
  flex-direction: column;
  gap: 8px;
  margin-top: 15px;

  & a {
    text-decoration: none;
    margin: 0 5px;
    font-size: 1.4rem;
    padding: 5px 20px;
    border-radius: 5px;
    transition: all .5s ease;
  }
}

.topLinks {
  display: flex;
  width: 100%;

  & a {
    flex: 1;
    background-color: $color-grey-5;
    color: $color-white;
    border: 2px solid $color-grey-5;

    &:hover {
      background-color: #ffffff;
      color: $color-grey-5;
      border: 2px solid $color-grey-5;
    }
  }
}

.bottomLink {
  background-color: $color-main;
  color: #ffffff;
  border: 2px solid $color-main;
  animation: downBounce 1.2s ease 2s infinite;

  &:hover {
    background-color: #ffffff;
    color: $color-main;
    border: 2px solid $color-main;
  }
}

@keyframes move {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes downBounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(0);
  }
}