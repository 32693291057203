$color-main: #ff6600;
$color-white: #ffffff;
$color-light: #f1f1f1;
$color-grey: #cccccc;
$color-black: #000000;
$color-grey-1: #afa5a5;
$color-grey-2: #b6b4bd33;
$color-grey-3: rgb(106, 107, 110);
$color-grey-4: rgba(182, 180, 189, 0.2);
$color-grey-5: #4b4848;
$color-grey-6: #161616;
$font-size-1: 50px;
$font-size-2: 110px;

// containers
$max-container-width: 1300px;

// media queries
$mid: 1080px;