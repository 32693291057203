@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';

.container {
  display: flex;
  width: 100%;
  flex-flow: column;
  align-items: center;
  margin: 0;
  margin-bottom: 5rem;
}

.content {
    display: flex;
    width: 100%;
    flex-flow: column;
    padding: .5rem;

}

.name {
  font-size: 3rem;
  font-weight: 700;
  color: $color-white;
  padding-top: 3rem;
}

.imageContainer {
  width: 95%;
  margin-top: 25px;
  border: 5px solid $color-grey-4;
  box-shadow: 0 5px 10px 10px $color-grey-4;
  background-color: $color-black;

  & img {
    width: 100%;
  }

  @include respond($mid) {
    flex: 1;
    margin-right: 4rem;
  }

  &.right {
    @include respond($mid) {
      order: 1;
      margin-right: 0;
      margin-left: 4rem;
    }
  }
}

.stack {
  margin: 1.5rem 0;
}

.actions {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
}

.action {
  padding: .7rem 3rem;
  border-radius: 5px;
  font-size: 1.4rem;
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
  transition: all .3s ease;
  margin-right: 20px;
}

.view {
  background-color: $color-main;
  color: $color-white;
  border: 2px solid $color-main;

  &:hover {
    background-color: $color-white;
    color: $color-main;
    border: 2px solid $color-main;
  }
}

.source {
  background-color: $color-grey-5;
  color: $color-white;
  border: 2px solid $color-grey-5;

  &:hover {
    background-color: $color-white;
    color: $color-grey-5;
    border: 2px solid $color-grey-5;
  }
}