@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 2rem;
  font-size: 2.5rem;
}

.section {
  display: flex;
  margin: 8px;
  padding: 12px 20px;
  font-weight: 400;
  color: #000000;
  border-radius: 16px;
  background-color: $color-grey-6;

  @include respond($mid) {
    margin: 20px;
    padding: 12px 20px;
  }

  &_item {
    background-color: $color-black;
    color: $color-white;
    border-radius: 5px;
    padding: 3px 15px;
    margin: 0px 4px;
    transition: all .5s;
    font-size: 1.4rem;
    border: none;

    @include respond($mid) {
      font-size: 1.7rem;
      padding: 5px 15px;
      margin: 0px 6px;
    }

    &:hover {
      background-color: $color-main;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

.name {
  color: $color-main;
}

.dot {
  color: $color-grey;
}