@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';

.main {
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 50px;

  @include respond($mid) {
    flex-direction: row;
    padding: 40px;
  }
}