@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';


.container {
  margin-bottom: 100px;

  p {
    padding: 1.5rem 0px;
    line-height: 1.5;
    color: $color-grey-1;
  }
}

.content {
  max-width: $max-container-width;
  margin: 0 auto;
  scroll-margin-top: 40px;

  h1 {
    font-size: $font-size-1;
    }

  h2 {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }

  @include respond($mid) {
    h1 {
      font-size: $font-size-2;
      line-height: $font-size-2;
      font-weight: 700;
      }
  }
}


.desc_2 {
  color: $color-main;
}