@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';

.container {
  margin-bottom: 100px;
  min-height: 70vh;

  p {
    padding: 1.7rem 0px;
    line-height: 1.4;
    color: $color-grey-1;
  }
}

.content {
  max-width: $max-container-width;
  margin: 0 auto;
  scroll-margin-top: 40px;

  h1 {
    font-size: $font-size-1;
    margin-bottom: 2rem;
    }

  h2 {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }

  @include respond($mid) {
    h1 {
      font-size: $font-size-2;
      line-height: $font-size-2;
      font-weight: 700;
      }
  }

  & a {
    text-decoration: none;
    color: $color-white;
    display: inline-block;
    transition: all .3s ease;

    &:hover {
      transform: translateY(-5px);
    }
  }
}


.desc_2 {
  color: $color-main;
}

.linkOut {
  font-weight: 700;
  color: $color-main;
}